<template>
    <div class="swich-button" >
        <div class="click-bet">
            <div class="one-bet">
                <div class="swich-input">
                    <input type="checkbox" id="flexCheckDefault" v-model="clickBetCheck" @change="betCheckChangeFunc()" />
                    <label class="form-check-label" for="flexCheckDefault">
                        {{translatedLangData('1-clickbet','1-Click Bet')}}
                    </label>
                </div>
                <!-- <span v-if="clickBetCheck && getClickBetSelectedValue()">
                    <label class="clickBet-value-label">{{ getKFormatter(getClickBetSelectedValue()) }}</label>
                </span> -->
            </div>
            <div class="any-odds">
                <div class="swich-input">
                    <input type="checkbox" id="acceptOdds" v-model="oddsvalue" @change="changeAcceptOdds()"/>
                    <label class="form-check-label" for="acceptOdds">
                        {{translatedLangData('accept-any-odds','ACCEPT any ODDS')}}
                    </label>
                </div>
            </div>
        </div>
        <div class="edit-stack">
            <div class="before-edit-stake" v-if="clickBetCheck && !showEditStakes">
                <div class="edit-stake-btn">
                    <form >
                        <button @click.prevent="selectInputValue(0),betCheckChangeFunc()"  class="edit-text" :class="{ 'active-color': inputvalue?.[0].isSelected }">
                            {{inputvalue?.[0].value}}
                        </button>
                        <button @click.prevent="selectInputValue(1),betCheckChangeFunc()"  class="edit-text" :class="{ 'active-color': inputvalue?.[1].isSelected }">
                            {{inputvalue?.[1].value}}
                        </button>
                        <button @click.prevent="selectInputValue(2),betCheckChangeFunc()"  class="edit-text" :class="{ 'active-color': inputvalue?.[2].isSelected }">
                            {{inputvalue?.[2].value}}
                        </button>
                        <button @click.prevent="selectInputValue(3),betCheckChangeFunc()"  class="edit-text" :class="{ 'active-color': inputvalue?.[3].isSelected }">
                            {{inputvalue?.[3].value}}
                        </button>
                        <button class="edit-text edit-btn" @click.prevent="showEditStakes=!showEditStakes">
                            {{translatedLangData('edit-stakes','Edit Stakes')}}
                            <div class="img-edit">
                                <img src="@/assets/images/feather-orderslip.webp" alt="" />
                            </div>
                        </button>
                    </form>
                </div>
                <!-- <div class="edit-input">
                    <button class="save-btn" @click.prevent="saveClickBetValue()">
                    save
                    <div class="img-edit">
                        <img src="@/assets/images/check-white.webp" alt="" />
                    </div>
                    </button>
                </div> -->
            </div>
            <div class="after-edit-stake" v-if="clickBetCheck && showEditStakes">
                <div class="edit-stake-btn" v-if="inputvalue">
                    <form action=''>
                        <div class="edit-input">
                            <input type="tel" :class="{ 'border-input': inputvalue?.[0].isSelected }" 
                                        v-model="inputvalue[0].value" class="btn-thm m-auto" @input="isNumber($event, 0),checkLimit(0)">
                        </div>
                        <div class="edit-input">
                             <input type="tel" :class="{ 'border-input': inputvalue?.[1].isSelected }" 
                                        v-model="inputvalue[1].value" class="btn-thm m-auto" @input="isNumber($event, 1),checkLimit(1)">
                        </div>
                        <div class="edit-input">
                             <input type="tel" :class="{ 'border-input': inputvalue?.[2].isSelected }" 
                                        v-model="inputvalue[2].value" class="btn-thm m-auto" @input="isNumber($event, 2),checkLimit(2)">
                        </div>
                        <div class="edit-input">
                             <input type="tel" :class="{ 'border-input': inputvalue?.[3].isSelected }" 
                                        v-model="inputvalue[3].value" class="btn-thm m-auto" @input="isNumber($event, 3),checkLimit(3)">
                        </div>
                        <div class="edit-input">
                            <button class="save-btn" @click.prevent="saveClickBetValue()">
                            {{translatedLangData('save','Save')}}
                            <div class="img-edit">
                                <img src="@/assets/images/check-white.webp" alt="" />
                            </div>
                            </button>
                        </div>
                    </form>
                </div>
                <div class="min-max-section">
                    <div class="min-max-con">
                        <h6>{{ translatedLangData('min','min')}} <span>100.00</span></h6>
                    </div>
                    <div class="min-max-icon">
                        <img src="@/assets/images/min-max-icon.54c08fee.webp" alt="">
                    </div>
                    <div class="min-max-con">
                        <h6>{{ translatedLangData('max','max')}} <span>5,000.00</span></h6>
                    </div>
                </div>
            </div>
        </div>
        <section class="edit-cancel-stake">
            <div class="header-stake">
            <div class="bet-slip">
                {{translatedLangData('bet-slip','BET SLIP')}}
            </div>
            <div class="edit-canel">
                <button class="edit-cancel-btn collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseExample" aria-expanded="false" aria-controls="collapseExample">
                    <div class="edit-stake">
                        <div class="edit-add">
                        <span> {{translatedLangData('edit-stakes','Edit Stakes')}}</span>
                        <div class="stake-img-edit">
                            <img src="@/assets/images/gear-edit-stake-red.webp" alt="" />
                        </div>
                        </div>
                    </div>

                    <div class="cancel-stake">
                        <div class="edit-add" ref="close_button" @click="resetChips()">
                            <span>{{translatedLangData('cancel-editing','Cancel Editing')}}</span>
                            <div class="stake-img-edit">
                                <img src="@/assets/images/x-edit-stake-red.webp" alt="" />
                            </div>
                        </div>
                    </div>
                </button>
            </div>
            </div>
            <div class="stake-body">
            <div class="collapse" id="collapseExample">
                <div class="card card-body">
                    <div class="alin-edit-stake" v-if="chips">
                        <div class="edit-stake-btn">
                        <form action="">
                            <div class="edit-input" v-for="(chipKey, index) in chipKeys" :key="index">
                                <input
                                    :class="lessThan100Set?.has(parseInt(chips[chipKey])) || duplicateSet?.has(parseInt(chips[chipKey])) ? 'border border-danger' : ''"
                                    type="text" @input="handleInput(chipKey)" @keypress="checkForNonNumericals($event)"
                                    v-model="chips[chipKey]" maxlength="8" class="h-auto" />
                            </div>
                            
                            <div class="edit-input">
                                <button class="save-btn" @click.prevent="callChangeStakeValue()">
                                    {{translatedLangData('save','Save')}}
                                    <div class="img-edit">
                                    <img src="@/assets/images/check-white.webp" alt="" />
                                    </div>
                                </button>
                            </div>
                        </form>
                        </div>
                    </div>
                </div>
            </div>
            </div>
        </section>

        <div class="tabs-btn">
            <form class="exch-custom-form mobile-input">
                <div class="row">
                    <div class="col-12 pb-3">
                        <label class="sm-font pb-2">{{translatedLangData('type','Type')}}</label>
                        <select v-model="filterOption.gameType" @change="selectedTypeFun($event)" class="form-select " aria-label="Default select example">
                            <option v-for="item in getBetsTypes()" :key="item.id" :value="item.id">
                                {{ translatedLangData(item.type.toLowerCase().trim().replaceAll(' ','-'), capitalizeFirstLetter(item.type))}}</option>
                        </select>
                    </div>
                </div>
            </form>
            <ul class="nav nav-pills mb-3" id="pills-tab" role="tablist">
                <li class="nav-item" role="presentation">
                    <button class="nav-link active" id="pills-profile-tab" data-bs-toggle="pill" data-bs-target="#pills-profile" type="button" role="tab" aria-controls="pills-profile" aria-selected="false">
                        {{translatedLangData('matched','Matched')}}
                        <div class="status">{{totalBetsCount}}</div>
                    </button>
                </li>
                <li class="nav-item" role="presentation">
                    <button class="nav-link" id="pills-ummatch-tab" data-bs-toggle="pill" data-bs-target="#pills-ummatch" type="button" role="tab" aria-controls="pills-ummatch" aria-selected="true">
                        {{translatedLangData('unmatched','Unmatched')}}
                        <div class="status">0</div>
                    </button>
                </li>
            </ul>
            <div class="tab-content" id="pills-tabContent">
                <div class="tab-pane fade" id="pills-ummatch" role="tabpanel" aria-labelledby="pills-ummatch-tab">
                    <div class="text-center">
                        <NoDataFound/>
                    </div>
                </div>
                <div class="tab-pane fade show active" id="pills-profile" role="tabpanel" aria-labelledby="pills-profile-tab">
                    <template v-if="filterEvents && filterEvents.length > 0">
                        <div class="matched-bet-outer">
                            <div class="matched-bet-inner">
                                <div class="place-bet-tab">
                                    <div class="betplace-header-title" v-if="filterOption.gameType != 'matka' && filterOption.gameType != 'fantasy_cricket'">
                                        <span>{{translatedLangData('selected-event','Selected Event')}}: </span>
                                        <div class="dropdown select-event-box">
                                            <button class="btn dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                                                {{currentEvent?.event_name}}
                                                <span class="count-digit-num">{{currentEvent?.event_count}}</span>
                                            </button>
                                            <ul class="dropdown-menu">
                                                <li><a class="dropdown-item" @click.prevent="selectEvent('all')">All</a> <span class="count-digit-num">{{totalBetsCount}}</span></li>
                                                <li v-for="(item,index) in openBetsList" :key="index">
                                                    <a class="dropdown-item" @click.prevent="selectEvent(item)">{{item?.event_name}}</a> <span class="count-digit-num">{{EventSum(item)}}</span>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                
                                    <div class="select-bets-table-body">
                                        <div class="select-event-inner-tabs" v-if="filterOption?.gameType != 'matka' && filterOption?.gameType != 'fantasy_cricket'">
                                            <ul class="nav nav-pills" id="pills-tab" role="tablist">
                                                <li class="nav-item" role="presentation" @click.prevent="currentTab = 'consolidate'">
                                                    <button class="nav-link active" id="consolidated-tab" data-bs-toggle="pill" data-bs-target="#consolidated" type="button" role="tab" aria-controls="consolidated" aria-selected="false">
                                                        {{translatedLangData('consolidated','Consolidated')}}
                                                    </button>
                                                </li>
                                                <li class="nav-item" role="presentation" @click.prevent="currentTab = 'avg'">
                                                    <button class="nav-link" id="average-tab" data-bs-toggle="pill" data-bs-target="#average" type="button" role="tab" aria-controls="average" aria-selected="true">
                                                        {{translatedLangData('average','Average')}}
                                                    </button>
                                                </li>
                                                <li class="nav-item" role="presentation" @click.prevent="currentTab = 'date'">
                                                    <button class="nav-link" id="event-tab" data-bs-toggle="pill" data-bs-target="#event" type="button" role="tab" aria-controls="event" aria-selected="true">
                                                        {{translatedLangData('event-date','Event Date')}}
                                                    </button>
                                                </li>
                                            </ul>
                                        </div>
                                        <template v-if="filterOption?.gameType == 'matka'">
                                            <div class="game-type-accordion-start bt-0 bets-accordion-wrapper" v-for="(item,index) in filterEvents" :key="index">
                                                <div class="accordion accordion-flush" id="accordionFlushExample">
                                                <div class="accordion-item">
                                                    <h2 class="accordion-header" id="flush-headingOne">
                                                        <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" :data-bs-target="'#flush-collapseOne' + index" aria-expanded="false" aria-controls="flush-collapseOne">
                                                            <p>{{item.event_name}}</p>
                                                            <span class="place-code place-date"> {{ getDateForMatka(item.bet_place_time) }} </span>
                                                        </button>
                                                    </h2>

                                                    <div :id="'flush-collapseOne' + index" class="accordion-collapse collapse" aria-labelledby="flush-headingOne" data-bs-parent="#accordionFlushExample">
                                                        <div class="accordion-body">
                                                            <div class="collapse-data">
                                                                <div class="time-sheet">
                                                                    <div class="row">
                                                                        <div class="col-12">
                                                                            <div class="balance-sheet">
                                                                            <div class="time-det balane-show">
                                                                                <span class="odds">
                                                                                    {{translatedLangData('event-name','Event Name')}}
                                                                                </span>
                                                                                <span class="statment">
                                                                                    {{item.event_name}}
                                                                                </span>
                                                                            </div>
                                                                            <div class="time-det balane-show">
                                                                                <span class="odds">
                                                                                    {{translatedLangData('selection','Selection')}}
                                                                                </span>
                                                                                <span class="statment">
                                                                                    {{item.selection}}
                                                                                </span>
                                                                            </div>
                                                                            <div class="time-det balane-show">
                                                                                <span class="odds">
                                                                                    {{translatedLangData('placing-time','Placing Time')}}
                                                                                </span>
                                                                                <span class="statment">
                                                                                    {{ getDateForMatka(item.bet_place_time) }}
                                                                                </span>
                                                                            </div>
                                                                            </div>
                                                                            <div>
                                                                                <ul class="d-flex">
                                                                                    <li class="matka-li">
                                                                                        <span>{{translatedLangData('rate','Rate')}}</span>
                                                                                        <span><strong>{{item?.rate}}</strong></span>
                                                                                    </li>
                                                                                    <li class="matka-li">
                                                                                        <span>{{translatedLangData('stake','Stake')}}</span>
                                                                                        <span><strong>{{item?.stake}}</strong></span>
                                                                                    </li>
                                                                                    <li class="matka-li">
                                                                                        <span>{{translatedLangData('pl','P&L')}}</span>
                                                                                        <span><strong>{{item?.p_l}}</strong></span>
                                                                                    </li>
                                                                                </ul>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                </div>
                                            </div>
                                        </template>
                                        <template v-else-if="filterOption?.gameType == 'fantasy_cricket'">
                                            <div class="game-type-accordion-start bt-0 bets-accordion-wrapper" v-for="(item,index) in filterEvents" :key="index">
                                                    <h2 class="accordion-header" id="flush-headingOne">
                                                        <button class="fantasy-tab accordion-button collapsed" @click.prevent="openFantasyModal(item)">
                                                            <div>
                                                                <p>{{item.event_name}}</p>
                                                                <span class="place-code place-date"> {{ displayDate(item.bet_place_time,'MM/ DD/ YYYY HH:mm:ss') }} </span>
                                                            </div>
                                                            <div class="fc-count">{{getTeamCount(item)}}</div>
                                                        </button>
                                                    </h2>
                                            </div>
                                        </template>
                                        <template v-else>
                                            <div class="place-bet-inner" v-for="(event, event_index) in filterEvents" :key="event_index">
                                            <h2 class="bet-slip-head">
                                                {{ event.event_name }}
                                            </h2>
                                            <div v-if="currentTab != 'avg'">
                                                <template v-if="event.main_market?.length">
                                                <div class="mat-Odds">
                                                    {{translatedLangData('match-odds','Match Odds')}}
                                                </div>
                                                <div class="table-responsive">
                                                    <table class="table">
                                                        <thead class="table-col">
                                                            <tr>
                                                                <th>{{translatedLangData('selection','Selection')}}</th>
                                                                <th>{{translatedLangData('odds','ODDS')}}</th>
                                                                <th>{{translatedLangData('stake','STAKE')}}</th>
                                                                <th>{{translatedLangData('pl','P&L')}}</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            <tr  v-for="(type, t_index) in event?.main_market" :key="t_index">
                                                                <td>{{type?.selection}}</td>
                                                                <td><span>{{ type?.odds }}</span></td>
                                                                <td><span>{{ type?.stake }}</span></td>
                                                                <td><span class="profit-add w-bold">{{ type?.pl }}</span></td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                                </template>
                                                <template v-if="event.bookmakers?.length">
                                                    <div class="mat-Odds">
                                                    {{translatedLangData('bookmaker','Bookmaker')}}
                                                    </div>
                                                    <div class="table-responsive">
                                                        <table class="table">
                                                            <thead class="table-col">
                                                                <tr>
                                                                    <th>{{translatedLangData('selection','Selection')}}</th>
                                                                    <th>{{translatedLangData('odds','ODDS')}}</th>
                                                                    <th>{{translatedLangData('stake','STAKE')}}</th>
                                                                    <th>{{translatedLangData('pl','P&L')}}</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                <tr v-for="(type, t_index) in event?.bookmakers" :key="t_index">
                                                                    <td>{{type?.selection}}</td>
                                                                    <td><span>{{ type?.odds }}</span></td>
                                                                    <td><span>{{ type?.stake }}</span></td>
                                                                    <td><span class="profit-add w-bold">{{ type?.pl }}</span></td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </template>
                                                <template  v-if="event.fancies?.length">
                                                    <div class="mat-Odds">
                                                        {{event?.fancies?.[0]?.selection}}
                                                    </div>
                                                    <div class="table-responsive">
                                                        <table class="table">
                                                            <thead class="table-col">
                                                                <tr>
                                                                    <th>{{translatedLangData('selection','Selection')}}</th>
                                                                    <th>{{translatedLangData('odds','ODDS')}}</th>
                                                                    <th>{{translatedLangData('stake','STAKE')}}</th>
                                                                    <th>{{translatedLangData('pl','P&L')}}</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                <tr v-for="(type, t_index) in event?.fancies"  :key="t_index">
                                                                    <td>{{type?.selection}}</td>
                                                                    <td><span>{{ type?.odds }}</span></td>
                                                                    <td><span>{{ type?.stake }}</span></td>
                                                                    <td><span class="profit-add w-bold">{{ type?.pl }}</span></td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </template>
                                                <template  v-if="filterOption?.gameType == 'matka'">
                                                    <div class="mat-Odds">
                                                        {{type?.[0]?.selection}}
                                                    </div>
                                                    <div class="table-responsive">
                                                        <table class="table">
                                                            <thead class="table-col">
                                                                <tr>
                                                                    <th>{{translatedLangData('selection','Selection')}}</th>
                                                                    <th>{{translatedLangData('odds','ODDS')}}</th>
                                                                    <th>{{translatedLangData('stake','STAKE')}}</th>
                                                                    <th>{{translatedLangData('pl','P&L')}}</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                <tr v-for="(type, t_index) in event?.fancies"  :key="t_index">
                                                                    <td>{{type?.selection}}</td>
                                                                    <td><span>{{ type?.odds }}</span></td>
                                                                    <td><span>{{ type?.stake }}</span></td>
                                                                    <td><span class="profit-add w-bold">{{ type?.p_l }}</span></td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </template>
                                            </div>
                                            <div v-if="currentTab == 'avg'">
                                                <template v-for="(type, t_index) in groupByIds(event?.main_market)"
                                                :key="t_index">
                                                <div class="mat-Odds">
                                                    {{translatedLangData('match-odds','Match Odds')}}
                                                </div>
                                                <div class="table-responsive">
                                                    <table class="table">
                                                        <thead class="table-col">
                                                            <tr>
                                                                <th>{{translatedLangData('odds','ODDS')}}</th>
                                                                <th>{{translatedLangData('stake','STAKE')}}</th>
                                                                <th>{{translatedLangData('pl','P&L')}}</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            <tr>
                                                                <td><span>{{ getAverageOdds(type) }}</span></td>
                                                                <td><span>{{ getAverageStake(type) }}</span></td>
                                                                <td><span class="profit-add w-bold">{{ getAveragePL(type) }}</span></td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                                </template>
                                                <template v-for="(type, t_index) in groupByIds(event?.bookmakers)"
                                                    :key="t_index" >
                                                    <div class="mat-Odds">
                                                     {{translatedLangData('bookmaker','Bookmaker')}}
                                                    </div>
                                                    <div class="table-responsive">
                                                        <table class="table">
                                                            <thead class="table-col">
                                                                <tr>
                                                                    <th>{{translatedLangData('odds','ODDS')}}</th>
                                                                    <th>{{translatedLangData('stake','STAKE')}}</th>
                                                                    <th>{{translatedLangData('pl','P&L')}}</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                <tr>
                                                                    <td><span>{{ getAverageOdds(type) }}</span></td>
                                                                    <td><span>{{ getAverageStake(type) }}</span></td>
                                                                    <td><span class="profit-add w-bold">{{ getAveragePL(type) }}</span></td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </template>
                                                <template v-for="(type, t_index) in groupByIds(event?.fancies)"
                                                    :key="t_index" >
                                                    <div class="mat-Odds">
                                                        {{type?.[0].selection}}
                                                    </div>
                                                    <div class="table-responsive">
                                                        <table class="table">
                                                            <thead class="table-col">
                                                                <tr>
                                                                    <th>{{translatedLangData('odds','ODDS')}}</th>
                                                                    <th>{{translatedLangData('stake','STAKE')}}</th>
                                                                    <th>{{translatedLangData('pl','P&L')}}</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                <tr>
                                                                    <td><span>{{ getAverageOdds(type) }}</span></td>
                                                                    <td><span>{{ getAverageStake(type) }}</span></td>
                                                                    <td><span class="profit-add w-bold">{{ getAveragePL(type) }}</span></td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </template>
                                            </div>
                                            </div>
                                        </template>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </template>
                    <template v-else>
                        <div class="text-center">
                            <NoDataFound/>
                        </div>
                    </template>
                </div>
            </div>
        </div>
        <FantasyBetDetailModal :betData="betData" />
    </div>
</template>
    
    <script>
    import { convertUTCDateToLocalDate, getSubtractedDaysDate } from '@/shared/utils/date-time';
    import { AUTH_TOKEN } from '@/shared/constants/cookies-const';
    import api from '@/shared/services/api';
    import NoDataFound from '@/shared/components/no-data/NoDataFound.vue';
    import FantasyBetDetailModal from './FantasyBetDetailModal.vue';
    import * as apiName from '@/shared/services/urls';
    import * as types from '@/modules/statements/utils/constants'
    import { kFormatter } from '@/shared/utils/formatter';
    import moment from 'moment';
    import { Modal,Offcanvas } from 'bootstrap';
    import { mapGetters } from 'vuex';

    // const sports = this.$store.getters.sportsData?.non_custom;
    // const extractedSports = sports?.slice(0, 3).map(obj => ({ id: obj.id, type: obj.name }));
    
    export default {
        name: "MybetSideMenu",
        components:{
            NoDataFound,
            FantasyBetDetailModal
        },
        inject:['translatedLangData'],
        data() {
            return {
                chips: JSON.parse(JSON.stringify(this.$store.getters.chips)),
                chipsDummy: null,
                duplicateSet: new Set(),
                lessThan100Set: new Set(),
                userData: null,
                clickBetCheck: false,
                inputvalue: null,
                showEditStakes:false,
                loading: false,
                serviceData: null,
                totalPage: null,
                pageNo: 1,
                pageEntries: 50,
                filterOption: null,
                selectedType:'1',
                openBetsList: null,
                totalOpenBetsCount:null,
                validate: false,
                oddsvalue: this.$store.getters.stateUser?.accept_any_odds,
                currentEvent:{
                    event_id:'all',
                    event_name: 'All',
                    event_count: this.totalBetsCount
                },
                currentTab : 'consolidate',
                betData: null,
                chipvalue:null,
                tempinputvalue:null,
                extractedSports:null
            }
        },
        computed:{
            chipKeys() {
                return Object.keys(this.chips).filter(key => key.startsWith('chip_val'));
            },
            filterEvents(){
                if(this.currentEvent?.event_id =='all'){
                    return this.openBetsList 
                }else{
                    var newList = this.openBetsList.filter(item => item.event_id == this.currentEvent?.event_id)
                    return newList
                }
            },
            ...mapGetters({
                mybetsTabActive: 'mybetsTabActive', chips:'chips'
            }),
            non_custom_sports(){
                return this.$store.getters.sportsData?.non_custom
            }
        },
        watch:{
            mybetsTabActive(){
                this.resetChips();
                this.showEditStakes = false;
                this.getOpenBetsServiceCall();
                this.userData = this.$store?.getters?.stateUser;
            }
        },
        created(){
            this.extractedSports = this.non_custom_sports?.slice(0, 3).map(obj => ({ id: obj.id, type: obj.name }));
            this.filterOption = {
                type: 1,
                betTypeOpt: 'sport',
                subType: this.extractedSports?.[0]?.id,
                gameType: this.extractedSports?.[0]?.id,
                fromDate: moment(new Date(getSubtractedDaysDate(new Date(), 15))).format('YYYY-MM-DD'),
                toDate: moment(new Date()).format('YYYY-MM-DD')
            };
            this.chips = { ...this.$store.getters.chips };
            this.chipsDummy = { ...this.$store.getters.chips };
            this.userData = this.$store.getters.stateUser;
            this.getOpenBetsServiceCall();
            if (this.getClickBetValue()) {
                this.clickBetCheck=true
                this.inputvalue = JSON.parse(JSON.stringify([...this.$store.getters.clickBetValue]))
            }
            else {
                this.inputvalue = [
                    {
                        value: 1000,
                        isSelected: true,
                    },
                    {
                        value: 2000,
                        isSelected: false,
                    },
                    {
                        value: 3000,
                        isSelected: false,
                    },
                    {
                        value: 4000,
                        isSelected: false,
                    }
                ]
            }
        },
        mounted() {
            // const sports = this.$store.getters.sportsData?.non_custom;
            // const extractedSports = this.non_custom_sports?.slice(0, 3).map(obj => ({ id: obj.id, type: obj.name }));
            
        },
        unmounted(){
            this.inputvalue = this.getClickBetValue()
        },
        methods: {
            checkDuplicates(){
                const dupValues = this.inputvalue.map(obj => obj.value);
                const uniqueValues = new Set(dupValues);
                return uniqueValues.size !== dupValues.length;
            },
            isNumber(evt, index) {
                let newValue = evt.target.value;
                newValue = newValue.replace(/\D/g, ''); // Remove all non-numeric characters
                if (newValue.length === 1 && newValue === '0') {
                    this.inputvalue[index].value = '';
                    evt.target.value = '';
                } else if (evt.data == '0' && newValue.startsWith('0')) {
                    evt.target.value = Number(this.inputvalue[index].value);
                } else if (/^\d+$/.test(newValue)) {
                    this.inputvalue[index].value = Number(newValue);
                } else {
                    if (newValue !== '') {
                        evt.target.value = this.inputvalue[index].value !== undefined ? this.inputvalue[index].value : '';
                    } else {
                        this.inputvalue[index].value = '';
                    }
                }
            },
            checkLimit(index) {
                let inpValue = this.inputvalue[index]
                inpValue.value = Math.max(0, Math.min(5000, inpValue.value));
            },
            hasDuplicatesChips() {
            const chipValValues = this.chipKeys.map(key => parseInt(this.chips[key] != '' ? this.chips[key] : 0));
            const valueSet = new Set();
            this.duplicateSet.clear();
            this.lessThan100Set.clear();
            for (const value of chipValValues) {
                if (value == 0 || value < 100 || value > 10000000) {
                    this.lessThan100Set.add(value);
                }
                else if (valueSet.has(value)) {
                    this.duplicateSet.add(value);
                } else {
                    valueSet.add(value);
                }
            }
            return this.lessThan100Set.size > 0 || this.duplicateSet.size > 0;
            },
            handleInput(key) {
                this.numberFormat(key);
                this.checkStakeLimit(key);
                this.hasDuplicatesChips();
            },
            numberFormat(key) {
                if (this.chips[key] != '') {
                    let stringValue = typeof this.chips[key] !== 'string' ? this.chips[key].toString() : this.chips[key];
                    stringValue = stringValue.replace(/[^0-9]/g, '');
                    stringValue = stringValue.replace(/[+-]/g, '');
                    this.chips[key] = stringValue === '' ? '' : parseInt(stringValue);
                }
            },
            checkForNonNumericals(event) {
                if (['e', '-', '+', '.'].includes(event.key.toLowerCase())) {
                    event.preventDefault();
                }
            },
            checkStakeLimit(index) {
                this.chips[index] = Math.max(0, Math.min(10000000, this.chips[index]));
            },
            EventSum(item){
                let count = item?.main_market?.length + item?.bookmakers?.length + item?.fancies?.length
                return count
            },
            openFantasyModal(item){
                this.betData = item;
                let newModal = new Modal('#fantasyModal');
                newModal.show();
                
            },
            getTeamCount(item){
                let count = item?.bet_with_run?.length + item?.bet_with_ball?.length + item?.bet_with_team?.length;
                return count;

            },
            displayDate(date,type){
                return moment(date).format(type)
            },
            selectEvent(item){
                if(item == 'all'){
                    this.currentEvent ={
                        event_id: 'all',
                        event_name:'All',
                        event_count:this.totalBetsCount
                    }
                }else{
                    this.currentEvent = item;
                    this.currentEvent.event_count = this.EventSum(item)
                }
            },
            changeAcceptOdds() {
                let user = this.$store.getters.stateUser;
                user.accept_any_odds = this.oddsvalue;
                this.$store.dispatch('setUser', user);
            },
            formatTimestamp(timestamp) {
                return moment(convertUTCDateToLocalDate(timestamp)).format("DD/MM/YYYY hh:mm:ss");
            },
            getDateTime(dateTime) {
                return moment.unix(dateTime).format('DD/MM/YYYY hh:mm:ss A')
            },
            getMatkaDateTime(dateTime) {
                return moment.utc(dateTime).add(5, 'hours').add(30, 'minutes').format('DD/MM/YYYY hh:mm:ss A')
            },
            gotoMatchMarket(item,type) {
                item.sportType = this.betTypeOpt;
                let allOffcanvas = document.querySelectorAll('.sidemenus-offcanvas.show');
                if(allOffcanvas.length) {
                    allOffcanvas.forEach(ofc => {
                    let canvasInstance = Offcanvas.getInstance(ofc);
                    canvasInstance.hide();
                    })
                }
                this.$router.push({name: 'match-market', params: {item: JSON.stringify(item), type: type}})
            },
            convertToTitleCase(str) {
                return str.split('_').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ');
            },
            selectedTypeFun(event) {
                this.filterOption.gameType = event.target.value;
                var item = event.target.value;
                if(item == "1" || item == "2" || item == "4") {
                    this.filterOption.betTypeOpt = 'sport';
                    this.filterOption.subType = Number(item);
                }else{
                    this.filterOption.betTypeOpt = item;
                    this.filterOption.subType = null;
                }
                this.getOpenBetsServiceCall();
            },
            digitOnly(event){
                event.target.value = event.target.value.replace(/\D/g, '');
            },

            getBetsTypes() {
                const updatedGames = [ ...types.NEW_STATIC_GAMES.filter(game => game.id != 'all' && game.id != 'dw')];
                return updatedGames;
            },
            capitalizeFirstLetter(string) {
                if (!string) return '';
                return string.split(' ').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ');
            },
            callChangeStakeValue() {
                if (!this.hasDuplicatesChips()) {
                    let chipsData = {
                        chips: {
                            ...this.chips
                        }
                    };
                    let headers = {
                        Authorization: `Bearer ${this.$cookies.get(AUTH_TOKEN)}`
                    };

                    this.loading = true;
                    api.post(apiName.CHANGE_STAKE_VALUES, chipsData, { headers }).then(response => {
                        this.loading = false;
                        if (response && response.status == 200) {
                            this.$store.dispatch('setChips', chipsData.chips);
                            this.showSuccessModalFunc(this.translatedLangData('saved-successfully','Saved Succesfully'));
                            this.$emit('save-btn');
                            this.$refs.close_button.click();
                        } else {
                        }
                    }).catch(error => {
                        this.loading = false;
                        if (error) {
                            this.showErrorModalFunc(error[0]);
                        }
                    });
                }
                else {
                    if (this.lessThan100Set) {
                        this.showErrorModalFunc(this.translatedLangData('stake-limit-msg', 'Stake Value Should Be Between 100 to 10000000'));
                    }

                    if (this.duplicateSet.size > 0) {
                        this.showErrorModalFunc(this.translatedLangData('duplicate-msg', 'Duplicate stake values are not allowed'));
                    }
                }
            },
            getOpenBetsServiceCall(type) {
                if(!this.checkIsLogin()) {
                    return false;
                }
                this.loading = true;
                this.totalBetsCount = 0;
                let data = {
                    "type": this.filterOption.betTypeOpt,
                    "sub_type": this.filterOption.subType,
                    "status": 'open',
                    "page_entries": this.pageEntries,
                    "from": this.filterOption.fromDate,
                    "to": this.filterOption.toDate,
                }
                let headers = {
                    'Authorization': `Bearer ${this.$cookies.get(AUTH_TOKEN)}`
                }
                api.post(apiName.OPEN_BETS_EVENT_LISTING, data, { headers }).then(response => {
                    this.loading = false;
                    this.openBetsList = [];
                    if (response) {
                        if (response.status == 200) {
                            if (response?.data?.status == 0) {
                                this.$emit('error-modal', response?.data?.debug[0])
                            }
                            else {
                                this.openBetsList = response?.data?.data?.data;
                                this.openBetsMatkaList = response?.data?.data?.data;    
                                let groupedBets = {};
                                if(this.filterOption.betTypeOpt=='sport'){
    
                                    this.openBetsList.forEach(bet => {
                                        if (bet.bookmakers && bet.bookmakers.length > 0) {
                                            if (!groupedBets.bookmakers) {
                                                groupedBets.bookmakers = [];
                                            }
                                            groupedBets.bookmakers.push(...bet.bookmakers);
                                        }
                                        if (bet.fancies && bet.fancies.length > 0) {
                                            if (!groupedBets.fancies) {
                                                groupedBets.fancies = [];
                                            }
                                            groupedBets.fancies.push(...bet.fancies);
                                        }
                                        if (bet.main_market && bet.main_market.length > 0) {
                                            if (!groupedBets.main_market) {
                                                groupedBets.main_market = [];
                                            }
                                            groupedBets.main_market.push(...bet.main_market);
                                        }
                                    });
        
                                    let groupedBetsLengths = {};
                                    Object.keys(groupedBets).forEach(key => {
                                        groupedBetsLengths[key] = groupedBets[key].length;
                                    });
        
                                    groupedBetsLengths.bookmakers = groupedBetsLengths.bookmakers || 0;
                                    groupedBetsLengths.fancies = groupedBetsLengths.fancies || 0;
                                    groupedBetsLengths.main_market = groupedBetsLengths.main_market || 0;
        
                                    this.openBetsGroupedData = groupedBets;
                                    this.totalBetsCount = groupedBetsLengths.bookmakers + groupedBetsLengths.fancies + groupedBetsLengths.main_market;
                                    this.selectEvent('all')
                                }
                                else if(this.filterOption.betTypeOpt=='matka' || this.filterOption.betTypeOpt=='fantasy_cricket'){
                                    this.openBetsList = this.openBetsMatkaList;
                                    this.totalBetsCount = this.openBetsMatkaList?.length;
                                }
                            }
                        }
                    }
                }).catch(error => {
                    this.loading = false;
                    if (error) {
                        this.$emit('error-modal', error[0])
                    }
                });
            },
    
             hasDuplicates(arr) {
                let uniqueSet = new Set(arr.map(item => String(item)));
                return uniqueSet.size !== arr.length;
            },
            betCheckChangeFunc() {
                this.chipvalue = JSON.parse(JSON.stringify(this.inputvalue))
                if (this.clickBetCheck) {
                    this.saveClickBetValue();
                    this.$store.dispatch('setClickBetValue', [...this.chipvalue]);
                    // this.$refs.open_click_bet_value.click();
                }
                else {
                    this.$store.dispatch('setClickBetValue', null);
                }
            },
            getClickBetValue() {
                return this.$store.getters.clickBetValue ? [...this.$store.getters.clickBetValue] : null;
            },
            getClickBetSelectedValue() {
                let inputValues = this.getClickBetValue();
                if (inputValues)
                    return inputValues.filter(item => item.isSelected)[0].value;
            },
            selectInputValue(index) {
                for (let i = 0; i < this.inputvalue.length; i++) {
                    this.inputvalue[i].isSelected = false;
                }
                this.inputvalue[index].isSelected = true;
            },
            saveClickBetValue() {
                for(let e of this.inputvalue){
                    if(!(e.value <= 5000 && e.value >= 100)){
                        this.$emit('error-modal',this.translatedLangData('value-btw-100-5000', 'Value should be in between 100 to 5000.'));
                        return;             
                    }
                }
                if(this.checkDuplicates()){
                    this.$emit('error-modal',this.translatedLangData('repeated-stake-values-are-not-allowed', 'Repeated stake values are not allowed!'));
                    return;
                }
                else{
                    this.tempinputvalue = []
                    this.inputvalue.forEach(elem => {
                        this.tempinputvalue.push(elem)
                    });
                    this.$store.dispatch('setClickBetValue', [...this.tempinputvalue]);
                    this.showEditStakes =  false; 
                    this.$emit('success-modal',this.translatedLangData('clickbet-updated', 'Click bet value updated successfully.'));
                }
            },
            getDateForMatka(date) {
                return moment.utc(date).add(5, 'hours').add(30, 'minutes').format('YYYY-MM-DD HH:mm:ss A')
            },
            checkIsLogin() {
                return this.$store.getters.isAuthenticated;
            },
            isNegative(num) {
                if (Math.sign(num) === -1) {
                    return true;
                }
                    return false;
            },
            getKFormatter(num) {
                return kFormatter(num);
            },
            resetChips() {
                this.chips = { ...this.$store?.getters?.chips };
            },
            showErrorModalFunc(message) {
                this.$emit('error-modal', message);
            },
            showSuccessModalFunc(message) {
                this.$emit('success-modal', message);
            },
            groupByIds(arr) {
                var marketTypes = {}
                arr?.forEach(item => {
                    const marketId = item.market_id;
                    if (!marketTypes[marketId]) {
                        marketTypes[marketId] = [];
                    }
                    marketTypes[marketId].push(item);
                });
                return marketTypes
            },
            getAverageOdds(data) {
                var averageOdds = 0
                for (var i = 0; i < data.length; i++) {
                    data[i].odds = parseFloat(data[i].odds)
                    averageOdds += (data[i].odds) / data.length
                }
                return averageOdds.toFixed(2);
            },
            getAverageStake(data) {
                var averageStake = 0
                for (var i = 0; i < data.length; i++) {
                    data[i].stake = parseFloat(data[i].stake)
                    averageStake += (data[i].stake) / data.length
                }
                return Math.round(averageStake);
            },
            getAveragePL(data) {
                var averagePL = 0
                for (var i = 0; i < data.length; i++) {
                    data[i].pl = parseFloat(data[i].pl)
                    averagePL += (data[i].pl) / data.length
                }
                return Math.round(averagePL);
            },
        },
    }
    </script>
    
    <style>
    .h-auto{
        height: auto !important;
    }
    .pl-2{
        padding-left: 5px;
    }
    
    .back-box {
        border: 1px solid blue;
        border-radius: 1rem;
    }
    .exch-open-bet-section {
        background-color: var(--dark-black-color);
        position: relative;
        overflow: hidden;
        display: flex;
        flex-direction: column;
        gap: 8px;
        margin: 15px 0;
    }
    .rectangle-1 {
        left: 0;
        top: 0;
        width: 8.3125rem;
        height: 5.625rem;
        position: absolute;
        background: linear-gradient(98deg, rgba(8, 248, 255, .8) .98%, rgba(59, 164, 253, .8) 51.52%, rgba(132, 54, 250, .8));
        filter: blur(97px);
        z-index: 0;
    }
    .exch-open-bet-section .cricket-card-head {
        gap: 5px;
    }
    .cricket-card-bg {
        background: linear-gradient(96deg, rgba(205, 66, 149, .3) 5.01%, rgba(143, 5, 189, .3) 92.79%);
        border-left: 1px solid #4a2444;
        border-right: 1px solid #35124e;
    }
    .cricket-card-head {
        display: flex;
        width: 100%;
        height: -moz-max-content;
        height: max-content;
        gap: 73px;
        padding: 6px 7px 6px 12px;
        position: relative;
        border-radius: .75rem .75rem 0 0;
        overflow: hidden;
        align-items: center;
    }
    .one {
        width: 88%;
        top: 0;
        left: 0;
        position: absolute;
        height: 100%;
        z-index: 1;
        border-radius: .75rem 0 0;
        clip-path: polygon(0 0, 100% 0, 93% 100%, 0 100%);
    }
    
    .one-cricket-bg {
        background: linear-gradient(90deg, rgba(242, 112, 156, 0), rgba(255, 102, 55, .15));
    }
    .cricket-2 {
        width: 29%;
        top: 0;
        right: 7%;
        position: absolute;
        height: 100%;
        z-index: 1;
        clip-path: polygon(0 0, 100% 0, 96% 100%, 0 100%);
    }
    .cricket-2-bg {
        background: linear-gradient(90deg, rgba(242, 112, 156, 0), rgba(255, 102, 55, .08));
    }
    .card-content-cricket {
        z-index: 1;
        display: flex;
        align-items: center;
        gap: 5px;
        flex: 1 0 0;
        background-color: transparent;
    }
    .cricket-icon {
        display: flex;
        width: 1rem;
        height: 1rem;
        justify-content: center;
        align-items: center;
        flex-shrink: 0;
        margin-right: .3rem;
    }
    .ellipsis {
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
    }
    
    .cricket-card-title {
        color: var(--body-color);
        font-size: .8125rem;
        font-style: normal;
        font-weight: 500;
        line-height: 150%;
        letter-spacing: .01875rem;
        flex: auto;
        display: flex;
        gap: .31rem;
        align-items: center;
    }
    .detail-sec {
        gap: 0 !important;
    }
    
    .exch-open-bet-body {
        padding: 0 12px;
        display: flex;
        flex-direction: column;
        gap: 8px;
        padding-bottom: 15px;
    }
    .font-gray-color {
        color: #929eb5;
    }
    
    .xs-font {
        color: var(--body-color);
        font-size: 12px;
        line-height: normal;
        letter-spacing: .015rem;
    }
    .team-list[data-v-305a7402] {
        padding: 6px 4px;
        margin-top: 8px;
    }
    @media screen and (max-width: 479px) {
        .team-list {
            padding: 6px 4px;
        }
    }
    .exch-open-bet-match-odd-sec {
        padding: 0 !important;
    }
    .team-list {
        padding: 5px 10px;
    }
    .exch-open-bet-match-odd-sec {
        display: flex;
        justify-content: space-between;
    }
    .team-list ul {
        display: flex;
        align-items: center;
    }
    .exch-open-bet-match-odd-sec ul {
        gap: 10px;
        flex: 1 1 0;
        flex-wrap: nowrap;
    }
    .team-list ul li {
        border-right: 1px solid #ccc;
        margin-right: 8px;
        padding-right: 8px !important;
        line-height: 21px;
    }
    .team-list ul li span {
        font-weight: 400;
        font-size: 14px;
        line-height: 18px;
        color: var(--white-color);
    }
    .team-list ul li span strong {
        word-wrap: break-word;
    }
    .lay-box {
        border: 1px solid red;
        border-radius: 1rem;
    }
    .detail-sec {
        gap: 0 !important;
    }
    @media screen and (max-width: 479px) {
        .open-bets-matka-list {
            padding: 10px 10px 10px 10px;
        }
    }
    .open-bets-matka-list {
        gap: 0 !important;
        border: 1px solid #7a7a9c;
        border-radius: .7rem;
        background-color: var(--dark-black-color);
        position: relative;
        overflow: hidden;
        display: flex;
        flex-direction: column;
        gap: 8px;
        margin: 15px 0;
        padding: 10px 12px 14px 15px;
    }
    .open-bets-matka-list .new-exch-open-bet-type-wrapper {
        margin-bottom: 10px;
    }
    .new-exch-open-bet-type-sec {
        width: 100%;
        display: flex;
        align-items: baseline;
        gap: 5px;
    }
    
    .font-gray-color {
        color: #929eb5;
    }
    .xs-font {
        color: var(--body-color);
        font-size: 12px;
        line-height: normal;
        letter-spacing: .015rem;
    }
    .new-exch-open-bet-type-right-sec {
        width: 70%;
        display: inline-block;
        padding-left: 5px;
    }
    .min-max-section {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        padding: 6px 0;
        text-transform: capitalize;
    }
    .min-max-con h6 {
        font-weight: 400;
        font-size: 12px;
        line-height: normal;
        color: var(--text-grey-color);
    }
    .min-max-icon {
        margin: 0 5px;
    }
    .matka-li{
        padding: 5px 10px;
        border: solid 1px #ccc !important;
        width: 100%;
        margin: 0;
        list-style: none;
    }
    .game-type-accordion-start .fantasy-tab::after{
        background: none !important;
        background-image: none !important;
    }
    .fantasy-tab{
        display: flex !important;
        width: 100%;
        justify-content: space-between !important;
        padding-right: 20px !important;
    }
    .fantasy-tab .fc-count {
        width: auto;
        height: auto;
        border-radius: 4px;
        color: rgb(35, 148, 252);
        background: var(--primary-color);
        padding: 2px 5px;
        line-height: normal;
        font-weight: 600;
    }
    </style>